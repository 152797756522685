<!--  -->
<template>
  <div class="form-container">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '忘记密码',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->

    <div class="top">
      <img
        class="logo"
        :src="require('@/assets/images/logo.png')"
        alt=""
        srcset=""
      />
      <p class="title">航都院OA</p>
    </div>
    <div class="box">
      <div class="password-content">
        <van-form :model="phoneForm" :rules="phoneRules" class="login-form">
          <input
            class="login-input"
            v-model.trim="phoneForm.phone"
            placeholder="请输入手机号"
            :maxlength="11"
          />
          <div class="check-msg">
            <input
              class="msg-input"
              v-model.trim="phoneForm.smsCode"
              placeholder="输入验证码"
              name="smsCode"
            />
            <template>
              <div class="count" v-if="isShow" type="text" @click="sendMsg">
                获取验证码
              </div>
              <div class="count" v-if="!isShow">
                <span>{{ count }}</span> 秒
              </div>
            </template>
          </div>
          <div class="pass-box">
            <input
              class="login-input-pass"
              :type="flag ? 'text' : 'password'"
              v-model.trim="phoneForm.rawPassword"
              placeholder="请输入新密码"
              maxlength="16"
              minlength="8"
            />
            <div class="eye-box" @click="showPass">
              <img :src="eye" alt="" class="eye" v-if="flag" />
              <img :src="closeEye" alt="" class="eye" v-else />
            </div>
          </div>
          <div class="pass-box">
            <input
              class="login-input-pass"
              :type="flag2 ? 'text' : 'password'"
              v-model.trim="phoneForm.confirmPassword"
              placeholder="再次输入新密码"
              maxlength="16"
              minlength="8"
            />
            <div class="eye-box" @click="showPass2">
              <img :src="eye" alt="" class="eye" v-if="flag2" />
              <img :src="closeEye" alt="" class="eye" v-else />
            </div>
          </div>
          <div class="buttons">
            <van-button
              type="primary"
              class="cancel-btn"
              :disabled="isLoading"
              @click="handleCancel"
              >返回</van-button
            >
            <van-button
              type="primary"
              class="confirm-btn"
              :disabled="isLoading"
              @click="handleConfirm"
              >确定</van-button
            >
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import Schema from "async-validator";
import { smsSend, forgetPassword } from "@/api/userauth/index.js";
import eye from "@/assets/images/login/eye.png";
import closeEye from "@/assets/images/login/closeEye.png";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (this.phoneForm.rawPassword != value) {
        return callback(new Error("两次输入密码不一致!"));
      }
      return callback();
    };
    const validatePassReg = (rule, value, callback) => {
      const reg =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,16}$/;
      if (!reg.test(value)) {
        return callback(
          new Error("密码包含大写字母、小写字母数字 和特殊符号，长度8-16位!")
        );
      }
      return callback();
    };
    return {
      count: 0,
      isShow: true, //通过 v-show 控制显示'获取按钮'还是'倒计时'
      flag: false,
      flag2: false,
      imgBg: require("@/assets/images/bg.gif"),
      logo: require("@/assets/images/left_logo.png"),
      isLoading: false,
      loginFormType: 0,

      phoneForm: {
        phone: "",
        smsCode: "",
        rawPassword: "",
        confirmPassword: "",
      },
      phoneRules: {
        phone: [{ required: true, trigger: "blur", message: "请输入手机号" }],
        smsCode: [{ required: true, trigger: "blur", message: "请输入验证码" }],
        rawPassword: [
          { required: true, trigger: "blur", message: "请输入新密码" },
          // {
          //   validator: validatePassReg,
          //   message: "密码包含大写字母、小写字母数字 和特殊符号，长度8-16位!",
          //   trigger: "blur",
          // },
          // {
          //   pattern: /[a-zA-Z0-9(_-@#&^~`*\(\)=+/)*]{8,16}/,
          // },
        ],
        confirmPassword: [
          { required: true, trigger: "blur", message: "请输入新密码" },
          {
            validator: validatePass,
            trigger: "blur",
          },
        ],
      },

      eye,
      closeEye,
    };
  },
  components: { TopWrapper },
  computed: {
    imgUrl() {
      return `${process.env.VUE_APP_BASE_URL}/account/imagecode?key=${this.phoneForm.imageKey}`;
    },
  },

  methods: {
    sendMsg() {
      if (!this.phoneForm.phone) {
        this.$toast("请输入手机号后，获取验证码");
        return;
      }
      smsSend({ phone: this.phoneForm.phone }).then((res) => {
        let { msg, success } = res;
        if (success) {
          this.changeTimer();
        }
        this.$toast(msg);
      });
    },
    changeTimer() {
      this.isShow = false; //倒计时
      this.count = 60; //赋值3秒
      var times = setInterval(() => {
        this.count--; //递减
        if (this.count <= 0) {
          // <=0 变成获取按钮
          this.isShow = true;
          clearInterval(times);
        }
      }, 1000); //1000毫秒后执行
    },
    /*
     *@des: 初始化校验
     */
    initPassValidator() {
      this.formValidator = new Schema(this.phoneRules);
    },
    /*
     *@des: 校验表单
     */
    passValidate() {
      this.initPassValidator();
      return new Promise((resolve, reject) => {
        this.formValidator
          .validate(this.phoneForm, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(async () => {
            resolve();
          })
          .catch((e) => {});
      });
    },
    handleConfirm() {
      this.passValidate().then((res) => {
        forgetPassword(this.phoneForm).then((res) => {
          let { data, success } = res;
          if (success) {
            this.$toast("密码修改成功，请在登录页登录");
            this.$router.replace("/login");
          }
        });
      });
    },

    handleCancel() {
      this.$router.replace("/login");
    },

    showPass() {
      this.flag = !this.flag;
    },
    showPass2() {
      this.flag2 = !this.flag2;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";
$bg: #fff;
$dark_gray: #889aa4;
$light_gray: #eee;

.form-container {
  width: 7.5rem;
  min-height: 100%;
  background-image: url("~@/assets/images/background2.png");
  background-size: 100% 100%;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  .top {
    width: 100%;
    text-align: center;
    .logo {
      width: 1.56rem;
      height: 1.56rem;
      margin-bottom: 0.3rem;
    }
    .title {
      height: 0.59rem;
      font-size: 0.42rem;
      font-weight: 600;
      color: #000000;
      line-height: 0.59rem;
      margin-bottom: 1.46rem;
    }
  }
  .box {
    width: 6.6rem;
    background: #ffffff;
    border-radius: 0.16rem;
    opacity: 0.79;
    border: 1px solid transparent;
    margin-left: 0.45rem;
    .password-content {
      .login-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0.3rem;
        .pass-box {
          position: relative;
          .eye-box {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
            width: 0.8rem;
            height: 0.8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            .eye {
              width: 0.21rem;
              height: 0.16rem;
            }
          }
        }
        .login-input {
          width: 6rem;
          height: 0.8rem;
          background: #ededed;
          border-radius: 0.08rem;
          margin-bottom: 0.3rem;
          padding: 0 0.2rem;
        }
        .login-input-pass {
          width: 6rem;
          height: 0.8rem;
          background: #ededed;
          border-radius: 0.08rem;
          margin-bottom: 0.3rem;
          padding: 0 1rem 0 0.2rem;
        }
        .check-msg {
          display: flex;
          width: 6rem;
          .msg-input {
            width: 4rem;
            height: 0.8rem;
            background: #ededed;
            border-radius: 0.08rem;
            margin-bottom: 0.3rem;
            padding: 0 0.2rem;
          }
          .count {
            width: 1.78rem;
            height: 0.8rem;
            background: #0094ee;
            border-radius: 0.08rem;
            color: #fff;
            font-size: 0.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 0.2rem;
          }
        }

        .buttons {
          margin-bottom: 0.4rem;
          display: flex;
          .cancel-btn {
            width: 2.4rem;
            height: 0.8rem;
            background: rgba(0, 148, 238, 0.1);
            border-radius: 0.4rem;
            font-size: 0.26rem;
            color: #0094ee;
            border: none;
          }
          .confirm-btn {
            width: 2.4rem;
            height: 0.8rem;
            background: #0094ee;
            border-radius: 0.4rem;
            font-size: 0.26rem;
            color: #fff;
            border: none;
            margin-left: 0.4rem;
          }
        }
      }

      .active {
        height: 100%;
        transition: height 2s;
      }
      .go-forget-pass {
        width: 100%;
        text-align: center;
        font-size: 0.28rem;
      }
    }
    .login-btn {
      width: 6rem;
      height: 0.8rem;
      margin-bottom: 0.3rem;
      font-size: 0.3rem;
      color: #ffffff;
      background: #0094ee;
      border-radius: 0.08rem;
      border: none;
    }
  }
}
</style>
